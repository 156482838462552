import "core-js/modules/es.array.push.js";
import { unref as _unref, resolveComponent as _resolveComponent, withKeys as _withKeys, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, isRef as _isRef, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5679f1f3"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "my-template-623",
  style: {
    "{\r\n        overflow": "hidden",
    "background-color": "#f4f4f4",
    "width": "100%",
    "height": "100%"
  }
};
const _hoisted_2 = {
  style: {
    "padding": "10px"
  }
};
const _hoisted_3 = {
  style: {
    "width": "60%",
    "margin-right": "10px"
  }
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  style: {
    "height": "30px",
    "line-height": "30px"
  }
};
const _hoisted_6 = {
  style: {
    "display": "flex",
    "align-items": "center",
    "flex-direction": "column"
  }
};
const _hoisted_7 = {
  style: {
    "margin-bottom": "20px"
  }
};
const _hoisted_8 = {
  style: {
    maxHeight: '800px',
    overflow: 'auto'
  }
};
import { ref, reactive, onMounted } from "vue";
import { myMixin } from "../mixin.js";
export default {
  __name: 'doc_user',
  setup(__props) {
    const {
      errorMsgBox,
      successMsgBox,
      apiAll,
      formDataApi,
      withLoading,
      page,
      agent,
      currentChange,
      sizeChange,
      getData,
      clearSearch,
      tableMaxHeight,
      account,
      getDialogWdith,
      getDialogLabelPosition
    } = myMixin();
    let currentRow = reactive({
      obj: {}
    });
    let selectedFields = reactive({
      obj: []
    });
    let dangerImg = require("../assets/危险.png");
    let fieldsShow = ref(false);
    let loadingExport = ref(false);
    let exportType = ref(1);
    let reqData = reactive({
      obj: {
        url: "/docUser/getUser",
        data: {
          name: "",
          agent_id: ""
        }
      }
    });
    let columns = reactive([{
      name: "finishInfo",
      title: "完成情况"
    }, {
      name: "order_no",
      title: "订单号"
    }, {
      name: "user_name",
      title: "姓名"
    }, {
      name: "city",
      title: "省份"
    }, {
      name: "company",
      title: "工作单位"
    }, {
      name: "position",
      title: "目标职称"
    }, {
      name: "review_dtme",
      title: "评审时间"
    }, {
      name: "employ_requirement",
      title: "收录要求"
    }, {
      name: "article_num",
      title: "篇数"
    }, {
      name: "price",
      title: "价格（/篇）"
    }, {
      name: "notice",
      title: "备注"
    },
    // {
    //   name: "user_status",
    //   title: "状态",
    // },
    {
      name: "create_dtme",
      title: "登记时间"
    }]);
    let imgList = reactive({
      obj: []
    });
    let dataList = reactive({
      obj: []
    });
    let smsWayShow = ref(false);
    onMounted(() => {
      doGetData();
    });
    function showEdit(row) {
      imgList.obj.push[{
        url: row.access_path
      }];
      currentRow.obj = row;
      smsWayShow.value = true;
    }
    function newWenan() {
      currentRow.obj = {};
      imgList.obj = [];
      currentRow.obj.agent_id = account._id;
      smsWayShow.value = true;
    }

    // async function doGetData() {
    //   let data = { agent_id: account._id };
    //   const res = await withLoading(apiAll(data, "/smsWay/getSmsWays"));
    //   dataList.obj = res.items;
    //   page.obj = res.page;
    // }

    async function doGetData() {
      const res = await getData(reqData.obj);
      dataList.obj = res.items;
      page.obj = res.page;
    }
    async function deleteById(id) {
      let data = {};
      const res = await withLoading(apiAll(data, "/docUser/delete/" + id));
      doGetData();
    }
    async function exportUser() {
      if (!selectedFields.obj || selectedFields.obj.length <= 0) {
        errorMsgBox("导出字段不能为空");
      } else {
        let data = {};
        data.selectedFields = selectedFields.obj;
        if (exportType.value == 1) {
          //导出当前，给出参数
          data.name = reqData.obj.data.name;
        }
        loadingExport.value = true;
        const res = await withLoading(apiAll(data, "/docExcel/addUserExcel"));
        loadingExport.value = false;
        if (res.code && res.code < 0) {
          errorMsgBox(res.code);
        } else {
          successMsgBox("导出成功");
          window.open(res.access_path);
        }
      }
    }
    async function addSmsWay() {
      if (!currentRow.obj.user_name) {
        errorMsgBox("姓名不能为空！");
        return false;
      }
      if (!currentRow.obj.company) {
        errorMsgBox("工作单位不能为空！");
        return false;
      }
      let formData = new FormData();
      if (imgList.obj && imgList.obj.length > 0) {
        formData.append("img", imgList.obj[0].raw);
      }
      formData.append("params", JSON.stringify(currentRow.obj));
      const res = await withLoading(formDataApi(formData, "/docUser/addUser"));
      if (res.code && res.code < 0) {
        errorMsgBox(res.message);
      } else {
        smsWayShow.value = false;
        doGetData();
      }
    }

    // async function updateSmsWay() {
    //   const res = await withLoading(
    //     apiAll(currentRow.obj, "/smsWenan/updateSmsWay/" + id)
    //   );
    //   doGetData();
    // }

    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_mobileList = _resolveComponent("mobileList");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_divider = _resolveComponent("el-divider");
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
      const _component_el_radio_button = _resolveComponent("el-radio-button");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_date_picker = _resolveComponent("el-date-picker");
      const _component_el_form = _resolveComponent("el-form");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
        gutter: 5,
        style: {
          margin: '15px 0 15px 0'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: _unref(agent).obj == 'Windows' ? 5 : 24,
          style: {
            "display": "flex",
            "margin-bottom": "10px"
          }
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_input, {
            placeholder: "输入名称",
            style: _normalizeStyle({
              height: _unref(agent).obj == 'Windows' ? '33px' : '22px'
            }),
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            modelValue: _unref(reqData).obj.data.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(reqData).obj.data.name = $event),
            clearable: "",
            onKeyup: _cache[1] || (_cache[1] = _withKeys($event => doGetData(), ["enter"])),
            onClear: _cache[2] || (_cache[2] = $event => _unref(clearSearch)(_unref(reqData), doGetData))
          }, null, 8, ["style", "size", "modelValue"])]), _createVNode(_component_el_button, {
            icon: "search",
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            type: "primary",
            onClick: _cache[3] || (_cache[3] = $event => doGetData())
          }, {
            default: _withCtx(() => [_createTextVNode(" 搜索 ")]),
            _: 1
          }, 8, ["size"])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: _unref(agent).obj == 'Windows' ? 14 : 24,
          style: {
            "text-align": "left",
            "margin-bottom": "10px"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            icon: "plus",
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            type: "primary",
            onClick: _cache[4] || (_cache[4] = $event => newWenan())
          }, {
            default: _withCtx(() => [_createTextVNode(" 登记用户 ")]),
            _: 1
          }, 8, ["size"]), _createVNode(_component_el_button, {
            icon: "upload",
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            type: "primary",
            onClick: _cache[5] || (_cache[5] = $event => _isRef(fieldsShow) ? fieldsShow.value = true : fieldsShow = true)
          }, {
            default: _withCtx(() => [_createTextVNode(" 用户导出 ")]),
            _: 1
          }, 8, ["size"])]),
          _: 1
        }, 8, ["span"])]),
        _: 1
      }), _unref(agent).obj == 'Windows' ? (_openBlock(), _createBlock(_component_el_table, {
        key: 0,
        data: _unref(dataList).obj,
        class: _normalizeClass(_ctx.tableClassNames),
        ref: "table",
        size: _ctx.tableSize,
        "min-height": "100",
        border: false,
        "highlight-current-row": true,
        "max-height": _unref(tableMaxHeight),
        style: {
          "width": "100%"
        },
        showHeader: true,
        onSelectionChange: _ctx.handleSelectionChange,
        "header-cell-style": {
          background: '#eef1f6',
          color: '#606266'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          fixed: "",
          "min-width": "40px"
        }, {
          default: _withCtx(scope => [scope.row.is_emergent && scope.row.order_status !== '完成' ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _unref(dangerImg),
            style: {
              "width": "20px",
              "height": "20px"
            }
          }, null, 8, _hoisted_4)) : _createCommentVNode("", true)]),
          _: 1
        }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns), (i, index) => {
          return _openBlock(), _createBlock(_component_el_table_column, {
            key: index,
            "show-overflow-tooltip": true,
            align: "center",
            look: index,
            "min-width": "120px",
            "column-key": index,
            width: "auto",
            class: "itemTrue",
            label: i.title
          }, {
            default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(scope.row[i.name]), 1)]),
            _: 2
          }, 1032, ["look", "column-key", "label"]);
        }), 128)), _createVNode(_component_el_table_column, {
          width: "230",
          align: "center",
          label: "操作",
          fixed: "right"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            size: "small",
            type: "primary",
            text: "",
            link: "",
            icon: "edit",
            onClick: $event => showEdit(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode(" 修改信息 ")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            size: "small",
            type: "danger",
            text: "",
            link: "",
            icon: "delete",
            onClick: $event => deleteById(scope.row._id)
          }, {
            default: _withCtx(() => [_createTextVNode(" 删除 ")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data", "class", "size", "max-height", "onSelectionChange"])) : (_openBlock(), _createBlock(_component_mobileList, {
        key: 1,
        isDelete: true,
        onShowEdit: showEdit,
        onDeleteById: deleteById,
        columns: _unref(columns),
        dataList: _unref(dataList)
      }, null, 8, ["columns", "dataList"]))]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_pagination, {
        total: _unref(page).obj.totalResult,
        "page-size": _unref(page).obj.pageSize,
        "current-page": _unref(page).obj.startPage,
        background: "",
        small: "",
        "page-sizes": [3, 6, 9, 12, 15, 20, 30],
        layout: "sizes,prev, pager, next",
        onSizeChange: _cache[6] || (_cache[6] = $event => _unref(sizeChange)($event, doGetData, _unref(reqData).obj)),
        onCurrentChange: _cache[7] || (_cache[7] = $event => _unref(currentChange)($event, doGetData, _unref(reqData).obj))
      }, null, 8, ["total", "page-size", "current-page"])]), _createElementVNode("div", null, [_createVNode(_component_el_dialog, {
        title: "导出数据",
        left: "",
        modelValue: _unref(fieldsShow),
        "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _isRef(fieldsShow) ? fieldsShow.value = $event : fieldsShow = $event),
        width: _unref(getDialogWdith)
      }, {
        default: _withCtx(() => [_withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_el_divider, null, {
          default: _withCtx(() => [_createTextVNode("字段选择")]),
          _: 1
        }), _createVNode(_component_el_checkbox_group, {
          modelValue: _unref(selectedFields).obj,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _unref(selectedFields).obj = $event),
          style: {
            "margin-bottom": "20px"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns), (i, index) => {
            return _openBlock(), _createBlock(_component_el_checkbox, {
              label: i,
              key: index
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(i.title), 1)]),
              _: 2
            }, 1032, ["label"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue"]), _createVNode(_component_el_divider, null, {
          default: _withCtx(() => [_createTextVNode("导出类型")]),
          _: 1
        }), _createElementVNode("div", _hoisted_7, [_createVNode(_component_el_radio_group, {
          modelValue: _unref(exportType),
          "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _isRef(exportType) ? exportType.value = $event : exportType = $event),
          size: _ctx.size
        }, {
          default: _withCtx(() => [_createVNode(_component_el_radio_button, {
            label: 1
          }, {
            default: _withCtx(() => [_createTextVNode("导出当前")]),
            _: 1
          }), _createVNode(_component_el_radio_button, {
            label: 0
          }, {
            default: _withCtx(() => [_createTextVNode("导出所有")]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "size"])]), _createVNode(_component_el_divider, null, {
          default: _withCtx(() => [_createTextVNode("确定导出")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          size: _ctx.size,
          icon: "select",
          onClick: exportUser
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定导出")]),
          _: 1
        }, 8, ["size"])])), [[_directive_loading, _unref(loadingExport)]])]),
        _: 1
      }, 8, ["modelValue", "width"]), _createVNode(_component_el_dialog, {
        title: "登记&修改用户",
        left: "",
        modelValue: _unref(smsWayShow),
        "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => _isRef(smsWayShow) ? smsWayShow.value = $event : smsWayShow = $event),
        width: _unref(getDialogWdith)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_8, [_createVNode(_component_el_form, {
          "label-width": "130px",
          "label-position": _unref(getDialogLabelPosition)
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "姓名",
            required: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(currentRow).obj.user_name,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _unref(currentRow).obj.user_name = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "省份",
            required: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(currentRow).obj.city,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => _unref(currentRow).obj.city = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "单位",
            required: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(currentRow).obj.company,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => _unref(currentRow).obj.company = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "目标职称",
            required: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(currentRow).obj.position,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => _unref(currentRow).obj.position = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "评审时间",
            required: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_date_picker, {
              modelValue: _unref(currentRow).obj.review_dtme,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => _unref(currentRow).obj.review_dtme = $event),
              type: "date",
              format: "YYYY-MM-DD",
              "value-format": "YYYY-MM-DD",
              placeholder: "选择评审时间",
              size: _ctx.size
            }, null, 8, ["modelValue", "size"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "收录要求",
            required: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "textarea",
              rows: 3,
              modelValue: _unref(currentRow).obj.employ_requirement,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => _unref(currentRow).obj.employ_requirement = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "篇数",
            required: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "number",
              modelValue: _unref(currentRow).obj.article_num,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => _unref(currentRow).obj.article_num = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "价格（/篇）",
            required: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "number",
              modelValue: _unref(currentRow).obj.price,
              "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => _unref(currentRow).obj.price = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "备注",
            required: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(currentRow).obj.notice,
              "onUpdate:modelValue": _cache[19] || (_cache[19] = $event => _unref(currentRow).obj.notice = $event),
              type: "textarea",
              rows: 3
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["label-position"])]), _createVNode(_component_el_button, {
          style: {
            "margin-left": "140px"
          },
          type: "primary",
          size: _ctx.size,
          icon: "select",
          onClick: addSmsWay
        }, {
          default: _withCtx(() => [_createTextVNode(" 提交")]),
          _: 1
        }, 8, ["size"])]),
        _: 1
      }, 8, ["modelValue", "width"])])]);
    };
  }
};