import "core-js/modules/es.array.push.js";
import { myMixin } from "./mixin.js";
const {
  errorMsgBox,
  successMsgBox,
  apiAll,
  withLoading
} = myMixin();
export default {
  data() {
    return {
      mainHeight: "",
      show: true,
      model: {
        account_name: "",
        password: ""
      },
      showError1: false,
      showError2: false,
      showError3: false,
      mobileError: "",
      focusPass: false,
      focusUserName: false,
      focusmobile: false,
      isRegist: false
    };
  },
  created() {
    this.getInit();
  },
  setup() {},
  methods: {
    getInit() {
      this.mainHeight = window.innerHeight + "px";
    },
    checkPass() {
      this.focusPass = false;
      this.showError2 = !this.model.password;
    },
    checkUserName() {
      this.focusUserName = false;
      this.showError1 = !this.model.account_name;
    },
    checkMobile() {
      //手机号正则
      if (this.model.mobile) {
        let regexp = "^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\\d{8}$";
        var reg = new RegExp(regexp);
        if (!reg.test(this.model.mobile)) {
          this.mobileError = "手机号格式不正确！";
        }
      }
      this.focusmobile = false;
      this.showError3 = !this.model.mobile;
    },
    async doLogin() {
      const res = await withLoading(apiAll(this.model, "/doc/doLogin"));
      if (res.code && res.code < 0) {
        errorMsgBox(res.msg);
      } else {
        window.sessionStorage.setItem("doc_account", JSON.stringify(res));
        if (res.account.account_auth == "user") {
          this.$router.push("/user_admin");
        } else {
          this.$router.push("/userIndex");
        }
      }
    }
  }
};