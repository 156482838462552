import "core-js/modules/es.array.push.js";
import { unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, isRef as _isRef, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3de5440e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "my-template-623",
  style: {
    "{\r\n        overflow": "hidden",
    "background-color": "#f4f4f4",
    "width": "100%",
    "height": "100%"
  }
};
const _hoisted_2 = {
  style: {
    "padding": "10px"
  }
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = {
  style: {
    "height": "30px",
    "line-height": "30px"
  }
};
const _hoisted_6 = {
  style: {
    "display": "flex",
    "align-items": "center",
    "flex-direction": "column"
  }
};
const _hoisted_7 = {
  style: {
    "margin-bottom": "20px"
  }
};
import { ref, reactive, onMounted } from "vue";
import { myMixin } from "../mixin.js";
export default {
  __name: 'doc_writer',
  setup(__props) {
    const {
      errorMsgBox,
      successMsgBox,
      apiAll,
      formDataApi,
      withLoading,
      page,
      agent,
      currentChange,
      sizeChange,
      getData,
      clearSearch,
      tableMaxHeight,
      account,
      getDialogWdith,
      getDialogLabelPosition
    } = myMixin();
    let currentRow = reactive({
      obj: {}
    });
    let reqData = reactive({
      obj: {
        url: "/docWriter/getWriter",
        data: {
          name: "",
          agent_id: ""
        }
      }
    });
    let columns = reactive([{
      name: "writer_name",
      title: "账户名"
    }, {
      name: "writer_age",
      title: "写手年龄",
      type: "number"
    }, {
      name: "writer_sex",
      title: "写手性别"
    }, {
      name: "writer_job",
      title: "写手职业"
    }, {
      name: "writer_info",
      title: "写手详情"
    }]);
    let columns1 = reactive([{
      name: "account_name",
      title: "账户名"
    }, {
      name: "password",
      title: "账户密码",
      type: "password"
    }, {
      name: "writer_name",
      title: "写手姓名"
    }, {
      name: "writer_age",
      title: "写手年龄",
      type: "number"
    }, {
      name: "writer_sex",
      title: "写手性别",
      type: "sex"
    }, {
      name: "writer_job",
      title: "写手职业"
    }, {
      name: "writer_info",
      title: "写手详情",
      type: "textarea"
    }]);
    let fieldsShow = ref(false);
    let selectedFields = reactive({
      obj: []
    });
    let exportType = ref(1);
    let imgList = reactive({
      obj: []
    });
    let users = reactive({
      obj: []
    });
    let dataList = reactive({
      obj: []
    });
    let smsWayShow = ref(false);
    onMounted(() => {
      reqData.obj.data.agent_id = account._id;
      currentRow.obj.agent_id = account._id;
      doGetData();
    });
    function showEdit(row) {
      imgList.obj.push[{
        url: row.access_path
      }];
      currentRow.obj = row;
      smsWayShow.value = true;
    }
    function newWenan() {
      currentRow.obj = {};
      smsWayShow.value = true;
    }
    async function doGetData() {
      const res = await getData(reqData.obj);
      dataList.obj = res.items;
      page.obj = res.page;
    }
    async function deleteById(id) {
      let data = {};
      const res = await withLoading(apiAll(data, "/docWriter/delete/" + id));
      doGetData();
    }
    async function handleUser(row) {
      let data = {};
      let status = row.status == 1 ? 0 : 1;
      const res = await withLoading(apiAll(data, "/doc/handleUser/" + row._id + "/" + status));
      doGetData();
    }
    async function addSmsWay() {
      const res = await withLoading(apiAll(currentRow.obj, "/docWriter/saveWriter"));
      if (res.code && res.code < 0) {
        errorMsgBox(res.message);
      } else {
        smsWayShow.value = false;
        doGetData();
      }
    }
    async function exportUser() {
      let data = {};
      const res = await withLoading(apiAll(data, "/docExcel/addPostExcel/"));
      if (res.code && res.code < 0) {
        errorMsgBox(res.code);
      } else {
        successMsgBox("导出成功");
        // let access_path = res.access_path;
        window.open(res.access_path);
      }
    }
    // async function updateSmsWay() {
    //   const res = await withLoading(
    //     apiAll(currentRow.obj, "/smsWenan/updateSmsWay/" + id)
    //   );
    //   doGetData();
    // }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_mobileList = _resolveComponent("mobileList");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_divider = _resolveComponent("el-divider");
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
      const _component_el_radio_button = _resolveComponent("el-radio-button");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_radio = _resolveComponent("el-radio");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
        gutter: 5,
        style: {
          margin: '15px 0 15px 0'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: _unref(agent).obj == 'Windows' ? 14 : 24,
          style: {
            "text-align": "left",
            "margin-bottom": "10px"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            icon: "plus",
            size: _unref(agent).obj == 'Windows' ? 'default' : 'small',
            type: "primary",
            onClick: _cache[0] || (_cache[0] = $event => newWenan())
          }, {
            default: _withCtx(() => [_createTextVNode(" 新增写手账户 ")]),
            _: 1
          }, 8, ["size"])]),
          _: 1
        }, 8, ["span"])]),
        _: 1
      }), _unref(agent).obj == 'Windows' ? (_openBlock(), _createBlock(_component_el_table, {
        key: 0,
        data: _unref(dataList).obj,
        class: _normalizeClass(_ctx.tableClassNames),
        ref: "table",
        size: _ctx.tableSize,
        "min-height": "100",
        border: false,
        "highlight-current-row": true,
        "max-height": _unref(tableMaxHeight),
        style: {
          "width": "100%"
        },
        showHeader: true,
        onSelectionChange: _ctx.handleSelectionChange,
        "header-cell-style": {
          background: '#eef1f6',
          color: '#606266'
        }
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns), (i, index) => {
          return _openBlock(), _createBlock(_component_el_table_column, {
            key: index,
            "show-overflow-tooltip": true,
            align: "center",
            look: index,
            "min-width": "180px",
            "column-key": index,
            width: "auto",
            class: "itemTrue",
            label: i.title
          }, {
            default: _withCtx(scope => [i.name == 'month' ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(scope.row[i.name]), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(scope.row[i.name]), 1))]),
            _: 2
          }, 1032, ["look", "column-key", "label"]);
        }), 128)), _createVNode(_component_el_table_column, {
          width: "230",
          align: "center",
          label: "操作",
          fixed: "right"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            size: "small",
            type: "primary",
            text: "",
            link: "",
            icon: "edit",
            onClick: $event => handleUser(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(scope.row.status == 1 ? "关闭账户" : "开启账户"), 1)]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            size: "small",
            type: "primary",
            text: "",
            link: "",
            icon: "edit",
            onClick: $event => showEdit(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode(" 修改信息 ")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            size: "small",
            type: "danger",
            text: "",
            link: "",
            icon: "delete",
            onClick: $event => deleteById(scope.row._id)
          }, {
            default: _withCtx(() => [_createTextVNode(" 删除 ")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data", "class", "size", "max-height", "onSelectionChange"])) : (_openBlock(), _createBlock(_component_mobileList, {
        key: 1,
        isDelete: true,
        onShowEdit: showEdit,
        onDeleteById: deleteById,
        columns: _unref(columns),
        dataList: _unref(dataList)
      }, null, 8, ["columns", "dataList"]))]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_pagination, {
        total: _unref(page).obj.totalResult,
        "page-size": _unref(page).obj.pageSize,
        "current-page": _unref(page).obj.startPage,
        background: "",
        small: "",
        "page-sizes": [3, 6, 9, 12, 15, 20, 30],
        layout: "sizes,prev, pager, next",
        onSizeChange: _cache[1] || (_cache[1] = $event => _unref(sizeChange)($event, doGetData, _unref(reqData).obj)),
        onCurrentChange: _cache[2] || (_cache[2] = $event => _unref(currentChange)($event, doGetData, _unref(reqData).obj))
      }, null, 8, ["total", "page-size", "current-page"])]), _createElementVNode("div", null, [_createVNode(_component_el_dialog, {
        title: "导出数据",
        left: "",
        modelValue: _unref(fieldsShow),
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _isRef(fieldsShow) ? fieldsShow.value = $event : fieldsShow = $event),
        width: _unref(getDialogWdith)
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createVNode(_component_el_divider, null, {
          default: _withCtx(() => [_createTextVNode("字段选择")]),
          _: 1
        }), _createVNode(_component_el_checkbox_group, {
          modelValue: _unref(selectedFields).obj,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _unref(selectedFields).obj = $event),
          style: {
            "margin-bottom": "20px"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns), (i, index) => {
            return _openBlock(), _createBlock(_component_el_checkbox, {
              label: i,
              key: index
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(i.title), 1)]),
              _: 2
            }, 1032, ["label"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue"]), _createVNode(_component_el_divider, null, {
          default: _withCtx(() => [_createTextVNode("导出类型")]),
          _: 1
        }), _createElementVNode("div", _hoisted_7, [_createVNode(_component_el_radio_group, {
          modelValue: _unref(exportType),
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _isRef(exportType) ? exportType.value = $event : exportType = $event),
          size: _ctx.size
        }, {
          default: _withCtx(() => [_createVNode(_component_el_radio_button, {
            label: 1
          }, {
            default: _withCtx(() => [_createTextVNode("导出当前")]),
            _: 1
          }), _createVNode(_component_el_radio_button, {
            label: 0
          }, {
            default: _withCtx(() => [_createTextVNode("导出所有")]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue", "size"])]), _createVNode(_component_el_divider, null, {
          default: _withCtx(() => [_createTextVNode("确定导出")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          size: _ctx.size,
          icon: "select",
          onClick: exportUser
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定导出")]),
          _: 1
        }, 8, ["size"])])]),
        _: 1
      }, 8, ["modelValue", "width"]), _createVNode(_component_el_dialog, {
        title: "登记&修改用户信息",
        left: "",
        modelValue: _unref(smsWayShow),
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _isRef(smsWayShow) ? smsWayShow.value = $event : smsWayShow = $event),
        width: _unref(getDialogWdith)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          "label-width": "130px",
          "label-position": _unref(getDialogLabelPosition),
          style: {
            "max-height": "400px",
            "overflow": "auto"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columns1), (i, index) => {
            return _openBlock(), _createBlock(_component_el_form_item, {
              label: i.title,
              key: index
            }, {
              default: _withCtx(() => [i.type == 'number' ? (_openBlock(), _createBlock(_component_el_input, {
                key: 0,
                type: "number",
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"])) : i.type == 'password' ? (_openBlock(), _createBlock(_component_el_input, {
                key: 1,
                type: "password",
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"])) : i.type == 'textarea' ? (_openBlock(), _createBlock(_component_el_input, {
                key: 2,
                type: "textarea",
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"])) : i.type == 'sex' ? (_openBlock(), _createBlock(_component_el_radio_group, {
                key: 3,
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, {
                default: _withCtx(() => [_createVNode(_component_el_radio, {
                  label: "男",
                  value: "男"
                }), _createVNode(_component_el_radio, {
                  label: "女",
                  value: "女"
                })]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue"])) : (_openBlock(), _createBlock(_component_el_input, {
                key: 4,
                modelValue: _unref(currentRow).obj[i.name],
                "onUpdate:modelValue": $event => _unref(currentRow).obj[i.name] = $event
              }, null, 8, ["modelValue", "onUpdate:modelValue"]))]),
              _: 2
            }, 1032, ["label"]);
          }), 128)), _createVNode(_component_el_form_item, {
            label: "",
            style: {
              "display": "flex",
              "justify-content": "center"
            }
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              type: "primary",
              size: _ctx.size,
              icon: "select",
              onClick: addSmsWay
            }, {
              default: _withCtx(() => [_createTextVNode(" 提交")]),
              _: 1
            }, 8, ["size"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["label-position"])]),
        _: 1
      }, 8, ["modelValue", "width"])])]);
    };
  }
};